import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showError } from '../../components/Messages';
import { LayoutParams } from '../../config/LayoutParams';
import { updateState } from '../../utils/Functions';
import { BaseController } from '../BaseController';
import React from 'react';

export default class EquipamentoController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'EquipamentoController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.numeroDeSerie,
            width: '10%',
            orderby: 'numeroDeSerie',
         },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.patrimonio,
            width: '10%',
            orderby: 'patrimonio',
         },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.nome,
            width: '15%',
            orderby: 'Nome',
         },

         {
            titulo: this.lang.telaDeCadastroDeEquipamento.local,
            width: '13%',
            orderby: 'NomeDoLocal',
         },

         {
            titulo: this.lang.telaDeCadastroDeEquipamento.predio,
            width: '12%',
            orderby: 'NomeDoPredio',
         },

         {
            titulo: this.lang.telaDeCadastroDeEquipamento.tag,
            width: '12%',
            orderby: 'MacDaTag',
         },

         {
            titulo: this.lang.telaDeCadastroDeEquipamento.temperatura,
            width: '8%',
            orderby: 'temperatura',
         },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.umidade,
            width: '7%',
            orderby: 'umidade',
         },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.situacao,
            width: '8%',
            orderby: 'situacao',
         },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.numeroDeSerie,
         item.patrimonio,
         item.nome,
         item.nomeDoLocal,
         item.nomeDoPredio,
         item.macDaTag,
         <div>
            {item.temperatura != null && item.temperatura !== undefined && (
               <React.Fragment>
                  <span>{item.temperatura}º&nbsp;</span>
                  <FontAwesomeIcon
                     style={{
                        color: LayoutParams.colors.corSecundaria,
                        fontSize: 20,
                     }}
                     cursor='pointer'
                     icon={faListAlt}
                     onClick={() => {
                        this.view.setState({
                           id: item.id,
                           nome: item.nome,
                           mostrarHistoricoDeTemperatura: true,
                        });
                     }}
                  />
               </React.Fragment>
            )}
         </div>,
         <div>
            {item.temperatura != null && item.temperatura !== undefined && (
               <React.Fragment>
                  <span>{item.umidade}%&nbsp;</span>
                  <FontAwesomeIcon
                     style={{
                        color: LayoutParams.colors.corSecundaria,
                        fontSize: 20,
                     }}
                     cursor='pointer'
                     icon={faListAlt}
                     onClick={() => {
                        this.view.setState({
                           id: item.id,
                           nome: item.nome,
                           mostrarHistoricoDeUmidade: true,
                        });
                     }}
                  />
               </React.Fragment>
            )}
         </div>,

         item.situacao.descricao,
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeONomeDoEquipamento);
            reject();
            return;
         }

         if (!item.numeroDeSerie) {
            showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeONumeroDeSerieDoEquipamento);
            reject();
            return;
         }

         var input = {
            nome: item.nome,
            numeroDeSerie: item.numeroDeSerie,
            situacao: item.situacao ? item.situacao.id : null,
            patrimonio: item.patrimonio,
            tag: item.tag,
            local: item.local,
            limiteInferiorDeTemperatura: item.limiteInferiorDeTemperatura,
            limiteSuperiorDeTemperatura: item.limiteSuperiorDeTemperatura,
            medicaoMinimaDeTemperatura: item.medicaoMinimaDeTemperatura,
            medicaoMaximaDeTemperatura: item.medicaoMaximaDeTemperatura,
            limiteInferiorDeUmidade: item.limiteInferiorDeUmidade,
            limiteSuperiorDeUmidade: item.limiteSuperiorDeUmidade,
            medicaoMinimaDeUmidade: item.medicaoMinimaDeUmidade,
            medicaoMaximaDeUmidade: item.medicaoMaximaDeUmidade,
            notificacoesDeBateriaFraca: item.notificacoesDeBateriaFraca,
            notificacoesDeMudancaDeTemperatura: item.notificacoesDeMudancaDeTemperatura,
            notificacoesDeMudancaDeUmidade: item.notificacoesDeMudancaDeUmidade,
            toleranciaParaNotificacaoDeTemperatura: item.toleranciaParaNotificacaoDeTemperatura,
            toleranciaParaNotificacaoDeUmidade: item.toleranciaParaNotificacaoDeUmidade,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   antesDeEditar = () => {
      return updateState(this.view, (state) => {
         state.dataDeFiltroDeLog = new Date();
         state.filtroDeLog = null;
         state.gateway = null;
         state.formPacotes = {};
         state.formLogs = {};
         state.formAlteracaoDeLocal = {};
         state.dataDeFiltroDeAlteracaoDeLocal = new Date();
      });
   };
}
