import React from 'react';
import { LayoutParams } from '../../config/LayoutParams';
import { Col } from 'react-bootstrap';
import Line from '../../components/Line';
import { dateToString, emptyStrIfNull, timeToString } from '../../utils/Functions';

export const CardDeMonitoramentoDeTemperaturaDeEquipamento = ({ equipamentos, setEquipamento, lang }) => {
   const getDataDaLeitura = (data) => {
      let result = '';
      let hoje = dateToString(new Date());
      let dataString = dateToString(data);

      if (hoje === dataString) {
         result = lang.telaDeMonitoramentoDeTemperaturaEUmidade.hoje;
      } else {
         result = dataString;
      }

      return result;
   };

   let result = !equipamentos
      ? null
      : equipamentos.map((item, index) => {
           return (
              <Col
                 key={index}
                 style={{
                    backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                    minWidth: 210,
                    maxWidth: 210,
                    borderRadius: 5,
                    margin: 5,
                    borderColor: LayoutParams.colors.corSecundaria,
                    borderStyle: 'solid',
                    color: LayoutParams.colors.corSecundaria,
                    padding: 0,
                 }}
              >
                 <div
                    style={{
                       display: 'flex',
                       flexDirection: 'row',
                       backgroundColor: LayoutParams.colors.corSecundaria,
                    }}
                 >
                    <div
                       style={{
                          display: 'table-cell',
                          width: '100%',
                          backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                          borderRadius: 5,
                       }}
                    >
                       <div
                          style={{  padding: 8, cursor: 'default' }}
                          onClick={() => {
                             // setEquipamento(item, 2);
                          }}
                       >
                          <span style={{ fontSize: 14, }}>{item.nome}</span>
                          <br/>
                          <span style={{ fontSize: 12, }}>{item.patrimonio}</span>
                       </div>
                    </div>

                    <div
                       style={{
                          display: 'table-cell',
                          width: 1,
                          borderLeft: '1px solid ' + LayoutParams.colors.corSecundaria,
                          marginBottom: 0,
                          marginLeft: 2,
                       }}
                    ></div>

                    <div
                       style={{
                          display: 'table-cell',
                          width: 100,
                          padding: '2px 4px 2px 1px',
                          backgroundColor: item.temperaturaIrregular
                             ? '#ffe300f0'
                             : LayoutParams.colors.corDoTemaPrincipal,
                          color: item.temperaturaIrregular ? 'red' : null,
                          borderRadius: 5,
                       }}
                    >
                       <div style={{ fontSize: 25, textAlign: 'right' }} title={lang.telaDeMonitoramentoDeTemperaturaEUmidade.temperaturaAtual}>
                          <strong>{item.temperaturaAtual ? `${item.temperaturaAtual}º` : '-'}</strong>
                       </div>
                       <div
                          style={{ display: 'flex', flexDirection: 'row', fontSize: 12, paddingLeft: 2 }}
                          title={lang.telaDeMonitoramentoDeTemperaturaEUmidade.minimaNaUltimaHora}
                       >
                          <div style={{ display: 'table-cell', minWidth: 35 }}>
                             <span>{lang.telaDeMonitoramentoDeTemperaturaEUmidade.minimaAbreviado + '.: '}</span>
                          </div>
                          <div
                             style={{ display: 'table-cell', textAlign: 'right', width: '100%', whiteSpace: 'nowrap' }}
                          >
                             <span>{item.temperaturaMinima != null ? `${item.temperaturaMinima}º` : ''}</span>
                          </div>
                       </div>

                       <div
                          style={{ display: 'flex', flexDirection: 'row', fontSize: 12, paddingLeft: 2 }}
                          title={lang.telaDeMonitoramentoDeTemperaturaEUmidade.maximaNaUltimaHora}
                       >
                          <div style={{ display: 'table-cell', minWidth: 35 }}>
                             <span>{lang.telaDeMonitoramentoDeTemperaturaEUmidade.maximaAbreviado + '.: '}</span>
                          </div>
                          <div
                             style={{ display: 'table-cell', textAlign: 'right', width: '100%', whiteSpace: 'nowrap' }}
                          >
                             <span>{item.temperaturaMaxima != null? `${item.temperaturaMaxima}º` : ''}</span>
                          </div>
                       </div>

                       <div
                          style={{ display: 'flex', flexDirection: 'column', fontSize: 10, paddingLeft: 2 }}
                          title={lang.telaDeMonitoramentoDeTemperaturaEUmidade.dataDaUltimaLeitura}
                       >
                          <div
                             style={{
                                display: 'table-cell',
                                minWidth: 35,
                                minHeight: 15,
                                color: item.informacaoDesatualizada ? 'red' : null,
                                backgroundColor: item.informacaoDesatualizada ? 'yellow' : null,
                                borderRadius: 5,
                                paddingLeft: 1,
                                paddingRight: 1,
                             }}
                          >
                             <span style={{ whiteSpace: 'nowrap' }}>
                                {emptyStrIfNull(getDataDaLeitura(item.dataDaUltimaAtualizacao)) +
                                   ' ' +
                                   emptyStrIfNull(timeToString(item.dataDaUltimaAtualizacao)).substring(0, 5)}
                             </span>
                          </div>
                       </div>
                    </div>
                 </div>

                 <Line color={LayoutParams.colors.corSecundaria} width={'100%'} height={2} />

                 <div
                    style={{
                       fontSize: 12,
                       height: 46,
                       backgroundColor: LayoutParams.colors.corSecundaria,
                    }}
                 >
                    <div
                       style={{
                          backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                          borderRadius: 5,
                          padding: 5,
                       }}
                    >
                       <div style={{ overflow: 'clip', height: 18 }} title={item.predio}>
                          <span>{item.predio}</span>
                       </div>
                       <div style={{ overflow: 'clip', height: 18 }} title={item.local}>
                          <span>{item.local}</span>
                       </div>
                    </div>
                 </div>
              </Col>
           );
        });

   return result;
};
