import imgLogo001 from './../contents/img/logo-001.svg';
import imgLogo002 from './../contents/img/logo-002.svg';

export const LayoutParams = {
    colors: {
        corDoTemaPrincipal: '#0B333C',
        corSecundaria: '#EDAB61',
        corDoTextoPadrao: '#EDAB61',
        fundoCinza: 'rgb(222, 226, 230)',
    },
    imgHomeLogo: imgLogo002,
    imgLogoLogin: imgLogo002,
    imgReportLogo: imgLogo001,
    imgLogoBrancoMarcaDagua: imgLogo001
};
