import { showError } from '../../components/Messages';
import { toEntityReference } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class LocalController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'LocalController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeLocal.predio, width: '20%', orderby: 'predio' },
         { titulo: this.lang.telaDeCadastroDeLocal.nome, width: '35%', orderby: 'Nome' },
         { titulo: this.lang.telaDeCadastroDeLocal.andar, width: '10%', orderby: 'andar' },
         { titulo: this.lang.telaDeCadastroDeLocal.descricao, width: '20%', orderby: 'descricao' },
         { titulo: this.lang.telaDeCadastroDeLocal.situacao, width: '15%', orderby: 'situacao' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.predio ? item.predio.nome : null,
         item.nome,
         item.andar,
         item.descricao,
         item.situacao ? item.situacao.descricao : null,
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeLocal.mensagens.informeONome);
            reject();
            return;
         }

         if (!item.descricao) {
            showError(this.lang.telaDeCadastroDeLocal.mensagens.informeADescricao);
            reject();
            return;
         }

         var input = {
            nome: item.nome,
            descricao: item.descricao,
            situacao: item.situacao ? item.situacao.id : null,
            predio: toEntityReference(item.predio),
            andar: item.andar
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
