export default class SessionManager {
   getLogin() {
      let result = JSON.parse(sessionStorage.getItem('usuario'));
      return result;
   }

   setLogin(usuario) {
      localStorage.setItem('lang', usuario.idioma);
      sessionStorage.setItem('usuario', JSON.stringify(usuario));
   }

   isAuthenticated() {
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      return usuario && usuario.token && window.location.pathname !== '/login';
   }

   temAcessoARotina(rotina) {
      let result = this.isAuthenticated() && this.getLogin().rotinasAcessiveis.some((i) => i.id === rotina);
      return result;
   }

   isUsuarioTecnometrics() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         result = usuario.isUsuarioTecnometrics && usuario.tipoDeAcesso === 'TECNOMETRICS';
      }
      return result;
   }

   isUsuarioDeEmpresa() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         result =
            usuario.isUsuarioDeEmpresa ||
            (usuario.isUsuarioTecnometrics && usuario.tipoDeAcesso === 'TECNOMETRICS-SUPERVISOR');
      }
      return result;
   }

   isUsuarioDeConsultaDeEmpresa() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         result = usuario.isUsuarioDeEmpresa && usuario.tipoDeAcesso === 'EMPRESA-CONSULTA';
      }
      return result;
   }

   isUsuarioTecnometricsSupervisor() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (
         window.location.pathname !== '/login' &&
         usuario &&
         usuario.token &&
         usuario.isUsuarioTecnometrics &&
         usuario.empresa
      ) {
         return true;
      }
      return result;
   }

   isContratoMedTrackComMapa = () => {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         let isEmpresa =
            usuario.isUsuarioDeEmpresa ||
            (usuario.isUsuarioTecnometrics && usuario.tipoDeAcesso === 'TECNOMETRICS-SUPERVISOR');
         result = isEmpresa && usuario.tipoDeContrato === 1;
      }
      return result; 
   };

   isContratoMedTrackSemMapa = () => {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         let isEmpresa =
            usuario.isUsuarioDeEmpresa ||
            (usuario.isUsuarioTecnometrics && usuario.tipoDeAcesso === 'TECNOMETRICS-SUPERVISOR');
         result = isEmpresa && usuario.tipoDeContrato === 2;
      }
      return result; 
   };

   isContratoSenseTrack = () => {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         let isEmpresa =
            usuario.isUsuarioDeEmpresa ||
            (usuario.isUsuarioTecnometrics && usuario.tipoDeAcesso === 'TECNOMETRICS-SUPERVISOR');
         result = isEmpresa && usuario.tipoDeContrato === 3;
      }
      return result;
   };

   aceitouTermosDeUso() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         result = usuario.aceitouTermosDeUso;
      }
      return result;
   }

   salvarFiltrosDoMapaInicial(filtros) {
      localStorage.setItem('filtros_mapa_home', JSON.stringify(filtros));
   }

   filtrosDoMapaInicial() {
      return JSON.parse(localStorage.getItem('filtros_mapa_home'));
   }

   getLang() {
      let result = localStorage.getItem('lang');

      if (!result || result === 'null') {
         var userLang = navigator.language || navigator.userLanguage;
         if (userLang === 'pt-BR' || userLang === 'en-US' || userLang === 'es') {
            result = userLang;
         } else {
            result = 'pt-BR';
         }
      }

      return result;
   }
}
