import React, { Fragment } from 'react';
import { LayoutParams } from '../../config/LayoutParams';
import { buildQueryString, formatDateTime } from '../../utils/Functions';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import moment from 'moment';

let LANG = {};

const STATUS = {
   Vazio: 1,
   Carregando: 2,
   Carregado: 3,
};

export class GraficoDeMonitoramentoDeTemperaturaDeEquipamento extends React.Component {
   constructor(props) {
      super(props);

      LANG = props.lang;

      this.state = {
         temperatura: props.item.temperatura,
         data: null,
         ultimoId: null,
         status: STATUS.Vazio,
      };
   }

   componentDidMount = () => {
      this.setState({ status: STATUS.Carregando });
      this.atualizar();

      clearInterval(this.refreshNotificacaoIntervalId);

      this.refreshNotificacaoIntervalId = setInterval(() => {
         if (!this.atualizando) {
            this.atualizar();
         }
      }, 5000);
   };

   componentWillUnmount = () => {
      clearInterval(this.refreshNotificacaoIntervalId);
   };

   getTemperatura = (faixaAnterior, faixaAtual, faixaSeguinte, amostras) => {
      let result = '';
      let menorAmostra = amostras[0];
      let maiorAmostra = amostras[amostras.length - 1];

      if (!faixaAnterior) {
         faixaAnterior = faixaAtual;
      }

      if (new Date(maiorAmostra.data) <= faixaAtual) {
         result = maiorAmostra.temperatura;
      } else if (new Date(menorAmostra.data) >= faixaAtual) {
         result = menorAmostra.temperatura;
      } else {
         let filtro = amostras.filter((i) => new Date(i.data) >= faixaAnterior && new Date(i.data) < faixaAtual);
         if (filtro.length > 0) {
            result = filtro[filtro.length - 1].temperatura;
         }
      }
      return result;
   };

   atualizar = () => {
      this.atualizando = true;

      if (!(this.props.faixas && this.props.faixas.length > 0)) {
         return;
      }

      let dataInicial = this.props.faixas[0];

      let filtro = {
         //monitorado: -1,
         monitorado: this.props.item.id,
         dataInicial: formatDateTime(dataInicial),
         ultimoId: this.state.ultimoId,
         //dataFinal: formatDateTime(this.props.faixas[this.props.faixas.length - 1]),
      };

      let query = buildQueryString(null, null, null, filtro);

      if (!this.leituras) {
         this.leituras = [];
      }

      this.leituras = this.leituras.filter((i) => new Date(i.data) > dataInicial);

      this.props.api
         .get('/equipamento/historicodetemperatura' + query, false, '', false, false)
         .then((result1) => {
            let status = STATUS.Vazio;

            let itens = [
               ['', 0, this.props.item.limiteSuperiorDeTemperatura, this.props.item.limiteInferiorDeTemperatura],
            ];

            let temperaturaAtual = this.state.temperatura;
            let ultimoId = this.state.ultimoId;

            let leituras = this.leituras;

            if (result1.count > 0) {
               leituras.push(...result1.items);
            }

            let temperaturaMinimaNoPeriodo = null;
            let temperaturaMaximaNoPeriodo = null;

            if (leituras.length > 0) {
               status = STATUS.Carregado;

               let ultimoItem = leituras[leituras.length - 1];
               ultimoId = ultimoItem.id;
               temperaturaAtual = ultimoItem.temperatura;

               temperaturaMinimaNoPeriodo = Math.min(...leituras.map((i) => i.temperatura));
               temperaturaMaximaNoPeriodo = Math.max(...leituras.map((i) => i.temperatura));

               /* let faixaAnterior = null;
               let faixaSeguinte = null;
               let quantidadeDeFaixas = this.props.faixas.length;
               let temperaturaAnterior = null;
               itens = this.props.faixas.map((faixaAtual, index) => {
                  faixaAnterior = index === 0 ? null : this.props.faixas[index - 1];
                  faixaSeguinte = index < quantidadeDeFaixas ? null : this.props.faixas[index + 1];
                  let temperatura = this.getTemperatura(faixaAnterior, faixaAtual, faixaSeguinte, leituras);
                  temperatura = temperatura ? temperatura : temperaturaAnterior;
                  temperaturaAnterior = temperatura;
                  return [
                     moment(faixaAtual).format('HH:mm'),
                     temperatura,
                     this.props.item.limiteSuperiorDeTemperatura,
                     this.props.item.limiteInferiorDeTemperatura,
                  ];
               });*/

               leituras.sort((a, b) => {
                  let keyA = a.id;
                  let keyB = b.id;
                  if (keyA < keyB) return -1;
                  if (keyA > keyB) return 1;
                  return 0;
               });

               itens = [];

               leituras.map((i) => {
                  itens.push([
                     moment(new Date(i.data)).format('HH:mm'),
                     i.temperatura,
                     this.props.item.limiteSuperiorDeTemperatura,
                     this.props.item.limiteInferiorDeTemperatura,
                  ]);
                  return null;
               });
            }

            const dadosDeTemperatura = [
               [
                  LANG.telaHomeEmpresa.dataHora,
                  LANG.telaHomeEmpresa.temperatura + ' (º)',
                  LANG.telaHomeEmpresa.limiteSuperior + ' (' + this.props.item.limiteSuperiorDeTemperatura + 'º)',
                  LANG.telaHomeEmpresa.limiteInferior + ' (' + this.props.item.limiteInferiorDeTemperatura + 'º)',
               ],
               ...itens,
            ];

            this.setState({
               dadosDeTemperatura: dadosDeTemperatura,
               temperatura: temperaturaAtual,
               temperaturaMinimaNoPeriodo: temperaturaMinimaNoPeriodo,
               temperaturaMaximaNoPeriodo: temperaturaMaximaNoPeriodo,
               ultimoId: ultimoId,
               status: status,
            });
         })
         .catch(() => {})
         .finally(() => {
            this.atualizando = false;
         });
   };

   render() {
      return (
         <Col sm={12} md={12} lg={6} xl={6}  className='px-2'>
            <div
               style={{
                  border: 'solid 1px ' + LayoutParams.colors.fundoCinza,
                  borderRadius: 8,
                  padding: 5,
                  marginBottom: 6
               }}
            >
               <div>
                  <span style={{ fontSize: 22, color: 'gray' }}>{this.props.item.nome}</span>
                  <br />
                  {true && (
                     <div style={{ display: 'flex' }}>
                        <div style={{ display: 'table-cell', width: 200 }}>
                           <span style={{ fontSize: 14, color: 'gray' }}>
                              {LANG.telaHomeEmpresa.temperaturaEmCelcius}
                           </span>
                        </div>
                        <div style={{ display: 'table-cell', color: 'gray' }}>
                           <span>{LANG.telaHomeEmpresa.minimo}:&nbsp;</span>
                           <span>{this.state.temperaturaMinimaNoPeriodo}º</span>
                           <span>&nbsp;&nbsp;{LANG.telaHomeEmpresa.maximo}:&nbsp;</span>
                           <span>{this.state.temperaturaMaximaNoPeriodo}º</span>
                        </div>
                     </div>
                  )}
               </div>

               {this.state.status === STATUS.Vazio && (
                  <div>
                     <span style={{ color: 'gray' }}> { '*' + LANG.telaHomeEmpresa.semInformacaoDeTemperatura}</span>
                  </div>
               )}

               {this.state.status === STATUS.Carregado && (
                  <Fragment>
                     <Row>
                        <Col style={{ maxWidth: 190, margin: 'auto' }}>
                           <FormGroup>
                              <Gauge
                                 maxHeight={this.props.maxHeight}
                                 minGate={this.props.item.limiteInferiorDeTemperatura}
                                 maxGate={this.props.item.limiteSuperiorDeTemperatura}
                                 min={this.props.item.medicaoMinimaDeTemperatura}
                                 max={this.props.item.medicaoMaximaDeTemperatura}
                                 value={this.state.temperatura}
                              />
                           </FormGroup>
                        </Col>
                        <Col style={{ maxHeight: this.props.maxHeight + 130, maxWidth: '90%', margin: 'auto' }}>
                           <FormGroup>
                              <Linha
                                 item={this.props.item}
                                 maxHeight={this.props.maxHeight}
                                 data={this.state.dadosDeTemperatura}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                  </Fragment>
               )}
            </div>
         </Col>
      );
   }
}

class Linha extends React.Component {
   render() {
      const options = {
         legend: { position: 'none' },
         chart: {
            title: null,
            subtitle: null,
         },
      };

      return this.props.data ? (
         <Chart chartType='Line' width='100%' height={this.props.maxHeight} data={this.props.data} options={options} />
      ) : (
         <div />
      );
   }
}

const Gauge = ({ maxHeight, value, min, max, minGate, maxGate }) => {
   const data = [
      ['Label', 'Value'],
      ['', value],
   ];

   const options = {
      width: 150,
      height: 150,

      minorTicks: 5,

      min: min,
      yellowColor: 'blue',
      yellowFrom: min,
      yellowTo: minGate,

      // greenColor: null,
      greenFrom: minGate,
      greenTo: maxGate,

      max: max,
      redColor: null,
      redFrom: maxGate,
      redTo: max,
   };

   return <Chart chartType='Gauge' width={150 + 'px'} height={150 + 'px'} options={options} data={data} />;
};
