import React, { Component } from 'react';
import HomeEmpresa from './Empresa/HomeEmpresa';
import HomeTecnometrics from './Tecnometrics/HomeTecnometrics';

export default class HomeView extends Component {
   render() {    
      return (
         <React.Fragment>
            {this.props.sessionManager.isUsuarioTecnometrics() && (
               <HomeTecnometrics
                  api={this.props.api}
                  lang={this.props.lang}
                  sessionManager={this.props.sessionManager}
               />
            )}
            {this.props.sessionManager.isUsuarioDeEmpresa() && (
               <HomeEmpresa api={this.props.api} lang={this.props.lang} sessionManager={this.props.sessionManager} />
            )}
         </React.Fragment>
      );
   }
}
