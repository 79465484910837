import { Container, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import { showError } from '../../components/Messages';
import { CardDeMonitoramentoDeTemperaturaDeEquipamento } from './CardDeMonitoramentoDeTemperaturaDeEquipamento';
import { GraficoDeMonitoramentoDeTemperaturaDeEquipamento } from './GraficoDeMonitoramentoDeTemperaturaDeEquipamento';
import moment from 'moment';
import RelatorioDeTemperaturaView from './RelatorioDeTemperaturaView';

const STATUS_DA_TELA = {
   Carregando: 1,
   Carregado: 2,
   Erro: 3,
};

const TIPO_DE_VISUALIZACAO = {
   Card: 1,
   Grafico: 2,
   Relatorio: 3,
};

export default class MonitoramentoDeTemperaturaEquipamentoView extends Component {
   constructor(props) {
      super(props);
      this.state = {
                  faixas: [],
         minutosAtras: 60,
      };
   }

   componentDidMount() {
      this.consultarEquipamentosId = setInterval(() => {
         this.props.consultarEquipamentos();
         this.montarFaixas();
      }, 30000);

      try {
         this.props.consultarEquipamentos();
      } catch (error) {
         showError(error);
      }

      try {
         this.montarFaixas();
      } catch (error) {
         showError(error);
      }
   }

   montarFaixas = () => {
      let dataInicial = moment(new Date()).subtract(this.state.minutosAtras, 'm').toDate();
      let minuto = moment(dataInicial).format('mm').substring(1);
      if (minuto === '1') {
         dataInicial = moment(dataInicial).subtract(1, 'm').toDate();
      } else if (minuto === '2') {
         dataInicial = moment(dataInicial).subtract(2, 'm').toDate();
      } else if (minuto === '3') {
         dataInicial = moment(dataInicial).subtract(3, 'm').toDate();
      } else if (minuto === '4') {
         dataInicial = moment(dataInicial).subtract(4, 'm').toDate();
      } else if (minuto === '6') {
         dataInicial = moment(dataInicial).subtract(1, 'm').toDate();
      } else if (minuto === '7') {
         dataInicial = moment(dataInicial).subtract(2, 'm').toDate();
      } else if (minuto === '8') {
         dataInicial = moment(dataInicial).subtract(3, 'm').toDate();
      } else if (minuto === '9') {
         dataInicial = moment(dataInicial).subtract(4, 'm').toDate();
      }

      let dataFinal = new Date();

      let data = dataInicial;
      let datas = [];
      while (data < dataFinal) {
         data = moment(data).add(5, 'm').toDate();
         datas.push(data);
      }
      this.setState({ faixas: datas });
   };

   componentWillUnmount = () => {
      clearInterval(this.consultarEquipamentosId);
   };

   setEquipamento = (equipamento, viewId) => {
      this.props.setEquipamento(equipamento, () => {
         this.setState({
            selectedViewNumber: viewId ? viewId : this.state.selectedViewNumber,
            titulo: viewId === 2 ? this.props.lang.telaDeMonitoramentoDeTemperaturaEUmidade.relatorio : this.state.titulo,
         });
      });
   };

   render() {
      let result = <div />;

      if (this.props.statusDaTela === STATUS_DA_TELA.Carregando) {
         result = (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
               <div style={{ margin: 'auto', paddingTop: 30 }}>
                  <span>{this.props.lang.telaDeMonitoramentoDeTemperaturaEUmidade.carregandoEquipamentos}</span>
               </div>
            </div>
         );
      }

      if (this.props.statusDaTela === STATUS_DA_TELA.Erro) {
         result = (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
               <div style={{ margin: 'auto', paddingTop: 30 }}>
                  <span>{this.props.mensagemDeErro}</span>
               </div>
            </div>
         );
      }

      if (
         this.props.statusDaTela === STATUS_DA_TELA.Carregado &&
         this.props.tipoDeVisualizacao === TIPO_DE_VISUALIZACAO.Card
      ) {
         result = (
            <div style={{ overflowY: 'auto', maxHeight: '100%', width: '100%' }}>
               <Row style={{ margin: 0 }}>
                  <CardDeMonitoramentoDeTemperaturaDeEquipamento
                     lang={this.props.lang}
                     equipamentos={this.props.equipamentos}
                     setEquipamento={(equipamento) => {
                        this.props.setEquipamento(equipamento);
                        // this.props.setTipoDeVisualizacao({ tipoDeVisualizacao: TIPO_DE_VISUALIZACAO.Relatorio });
                     }}
                  />
               </Row>
            </div>
         );
      }

      if (
         this.props.statusDaTela === STATUS_DA_TELA.Carregado &&
         this.props.tipoDeVisualizacao === TIPO_DE_VISUALIZACAO.Grafico
      ) {
         result = (
            <div style={{ overflowY: 'auto', maxHeight: '100%', maxWidth: '100%', overflowX: 'hidden' }}>
               <Container fluid style={{ padding: 0, paddingTop: 5 }}>
                  <Row className='mx-0'>
                     {this.props.equipamentos.map((item, index) => {
                        return (
                           <GraficoDeMonitoramentoDeTemperaturaDeEquipamento
                              key={index}
                              item={item}
                              lang={this.props.lang}
                              api={this.props.api}
                              faixas={this.state.faixas}
                           />
                        );
                     })}
                  </Row>
               </Container>
            </div>
         );
      }

      if (
         this.props.statusDaTela === STATUS_DA_TELA.Carregado &&
         this.props.tipoDeVisualizacao === TIPO_DE_VISUALIZACAO.Relatorio
      ) {
         result = (
            <div style={{ overflowY: 'auto', maxHeight: '100%', maxWidth: '100%', overflowX: 'hidden' }}>
               <RelatorioDeTemperaturaView
                  lang={this.props.lang}
                  api={this.props.api}
                  getEquipamento={() => this.props.equipamento}
               />
            </div>
         );
      }

      return result;
   }
}
