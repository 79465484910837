import React, { Component } from 'react';
import RelatorioDeTemperaturaView from './RelatorioDeTemperaturaView';
import RelatorioDeUmidadeView from './RelatorioDeUmidadeView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { LayoutParams } from '../../config/LayoutParams';

export default class RelatorioView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         relatorioSelecionado: null,
      };
   }

   render = () => {
      return (
         <React.Fragment>
            {!this.state.relatorioSelecionado && (
               <React.Fragment>
                  {this.itemDeRelatorio('relatorioDeTemperatura', 'Relatório de Temperatura')}
                  {this.itemDeRelatorio('relatorioDeUmidade', 'Relatório de Umidade')}
               </React.Fragment>
            )}

            {this.state.relatorioSelecionado && (
               <div
                  style={{ color: '#999', margin: 5, cursor: 'pointer' }}
                  onClick={() => this.setRelatorioSelecionado(null)}
               >
                  <span>Voltar</span>
               </div>
            )}

            {this.state.relatorioSelecionado === 'relatorioDeTemperatura' && this.relatorioDeTemperatura()}
            {this.state.relatorioSelecionado === 'relatorioDeUmidade' && this.relatorioDeUmidade()}
         </React.Fragment>
      );
   };

   setRelatorioSelecionado = (nome) => {
      this.setState({ relatorioSelecionado: nome });
   };

   relatorioDeTemperatura = () => {
      return <RelatorioDeTemperaturaView lang={this.props.lang} api={this.props.api} />;
   };

   relatorioDeUmidade = () => {
      return <RelatorioDeUmidadeView lang={this.props.lang} api={this.props.api} />;
   };

   itemDeRelatorio = (nome, descricao) => {
      return (
         <div
            style={{
               display: 'flex',
               color: LayoutParams.colors.corDoTemaPrincipal,
               margin: 5,
               cursor: 'pointer',
               paddingLeft: 8,                
            }}
            onClick={() => this.setRelatorioSelecionado(nome)}
         >
            <div style={{ display: 'flex' }}>
               <div style={{ display: 'table-cell' }}>
                  <FontAwesomeIcon icon={faPrint} />
               </div>
               <div style={{ display: 'table-cell', paddingLeft: 8 }}>{descricao}</div>
            </div>
         </div>
      );
   };
}
