import { dateTimeToString } from '../utils/Functions';
import { BaseController } from './BaseController';

export default class LogController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'LogController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: 'Categoria', width: '5%', orderby: 'SubCategory' },
         { titulo: 'Data', width: '5%', orderby: 'Date' , className: "" },
         { titulo: 'Ação', width: '25%', orderby: 'Action' },
         { titulo: 'Método', width: '5%', orderby: 'HttpMethod' },
         { titulo: 'HTTP', width: '5%', orderby: 'HttpResponseCode' },
         { titulo: 'Usuário', width: '5%', orderby: 'UserId' },
         { titulo: 'Mensagem', width: '50%', orderby: 'HttpResponseCode', className: 'multilineTableData' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.subCategory.description,
         <span style={{wordBreak: "break-all"}}>{ dateTimeToString(item.date)}</span>,
         <span style={{wordBreak: "break-all"}}>{item.action}</span>,
         item.httpMethod,
         item.httpResponseCode,
         item.userId,
         item.message,
      ];
   };

   getObjetoDeDados = () => {};
}
