import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { LayoutParams } from '../../config/LayoutParams';
import LoginNovoView from '../User/LoginNovoView';
import LogoffView from '../User/LogoffView';
import NotFoundView from '../NotFoundView';
import ErrorView from '../ErrorView';
import MainLayout from '../../components/MainLayout';
import DemonstracaoDeComponentesView from '../DemonstracaoDeComponentesView';
import HomeView from '../HomeView';
import UsuarioDeEmpresaView from './UsuarioDeEmpresaView';
import ParametroDoSistemaView from '../Empresa/ParametroDoSistemaView';
import CargoDeEmpresaView from './CargoDeEmpresaView';
import PerfilDeUsuarioView from './PerfilDeUsuarioView';
import AboutView from '../AboutView';
import TermosDeUsoView from '../TermosDeUso';
import AccountView from './../AccountView';
import TagView from '../Empresa/TagView';
import LocalView from '../Empresa/LocalView';
import EquipamentoView from '../Empresa/EquipamentoView';
import GatewayView from '../Empresa/GatewayView';
import PredioView from './PredioView';
import ImportarEquipamentos from '../Empresa/ImportarEquipamentos';
import PainelDeControleView from '../Empresa/PainelDeControleView';
import ServicoDeNotificacaoView from '../Empresa/ServicoDeNotificacaoView';

export default class LayoutEmpresa extends React.Component {
   render() {
      let sessionManager = this.props.sessionManager;
      var lang = this.props.lang;
      this.api = this.props.api;
      return (
         <HashRouter>
            <MainLayout
               menuItems={this.props.menus.getMenusDeEmpresa(sessionManager, lang)}
               imgHomeLogo={LayoutParams.imgHomeLogo}
               login={this.props.login}
               lang={lang}
               mostrarDadosDaConta={true}
               alterarIdioma={this.props.alterarIdioma}
               api={this.api}
            >
               <Switch>
                  <Route
                     exact
                     path='/'
                     render={() => <HomeView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/login'
                     render={() => <LoginNovoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route path='/logoff' render={() => <LogoffView api={this.api} lang={lang} />} />
                  <Route
                     path='/error'
                     render={() => <ErrorView api={this.api} lang={lang} error={this.state.error} />}
                  />
                  <Route path='/components' render={() => <DemonstracaoDeComponentesView api={this.api} />} />
                  <Route path='/about' render={() => <AboutView api={this.api} lang={lang} />} />
                  <Route
                     path='/account'
                     render={() => (
                        <AccountView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1121'
                     render={() => <UsuarioDeEmpresaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/9999'
                     render={() => (
                        <DemonstracaoDeComponentesView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1041'
                     render={() => (
                        <ParametroDoSistemaView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1141'
                     render={() => <CargoDeEmpresaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1011'
                     render={() => <PerfilDeUsuarioView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1411'
                     render={() => <GatewayView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1401'
                     render={() => <TagView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1421'
                     render={() => <LocalView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1431'
                     render={() => (
                        <EquipamentoView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           mostrarHistorico={true}
                        />
                     )}
                  />

                  <Route
                     path='/90003'
                     render={() => <TermosDeUsoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1461'
                     render={() => <PredioView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/importarequipamentos'
                     render={() => <ImportarEquipamentos api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/panel'
                     render={() => <PainelDeControleView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1481'
                     render={() => (
                        <ServicoDeNotificacaoView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route render={() => <NotFoundView lang={lang} />} />
               </Switch>
            </MainLayout>
         </HashRouter>
      );
   }
}
