import { showError } from '../../components/Messages';
import { dateTimeToString } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class EventoController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'EventoController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeEvento.dataDaIrregularidade, width: '12%', orderby: 'DataDaIrregularidade' },
         { titulo: this.lang.telaDeCadastroDeEvento.equipamento + '/Gateway', width: '20%' },
         { titulo: this.lang.telaDeCadastroDeEvento.descricao, width: '20%' },
         { titulo: this.lang.telaDeCadastroDeEvento.regularizacao, width: '10%', orderby: 'DataDeRegularizacao' },
         { titulo: this.lang.telaDeCadastroDeEvento.duracao, width: '10%', orderby: null },
         { titulo: this.lang.telaDeCadastroDeEvento.local, width: '10%' },
         { titulo: this.lang.telaDeCadastroDeEvento.predio, width: '10%' },
         { titulo: this.lang.telaDeCadastroDeEvento.situacao, width: '10%', orderby: 'situacao' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         dateTimeToString(item.dataDaIrregularidade),
         item.monitorado ? item.monitorado.nome : item.gateway ? item.gateway.mac : null,
         item.descricaoDoEvento,
         dateTimeToString(item.dataDeRegularizacao),
         item.duracao,
         item.nomeDoLocal,
         item.nomeDoPredio,
         item.situacao ? item.situacao.descricao : null,
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (item.situacao.id === 2 && item) {
            if (!item.causaProvavel) {
               showError('Informe a causa provável do evento.');
               reject();
               return;
            }

            if (!item.acaoRealizadaParaRegularizacao) {
               showError('Informe a ação realizada para regularização.');
               reject();
               return;
            }

            if (!item.acaoRealizadaParaPrevenirReincidencia) {
               showError('Informe a ação realizada para previnir reincidência.');
               reject();
               return;
            }
         }

         var input = {
            situacao: item.situacao ? item.situacao.id : null,
            causaProvavel: item.causaProvavel,
            acaoRealizadaParaRegularizacao: item.acaoRealizadaParaRegularizacao,
            acaoRealizadaParaPrevenirReincidencia: item.acaoRealizadaParaPrevenirReincidencia,
            observacao: item.observacao,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
