import React from 'react';
import { LayoutParams } from '../../config/LayoutParams';
import { faExclamationTriangle, faTemperatureLow, faTint } from '@fortawesome/free-solid-svg-icons';
import { ActionButton } from '../../components/ActionButton';
import MonitoramentoDeTemperaturaEquipamentoView from './MonitoramentoDeTemperaturaEquipamentoView';
import MonitoramentoDeUmidadeEquipamentoView from './MonitoramentoDeUmidadeEquipamentoView';
import EventoView from './EventoView';
import { ViewController } from '../../components/ViewController';
import { buildQueryString, prepareSearchable } from '../../utils/Functions';
import { faTachometerAlt, faPager, faChartBar } from '@fortawesome/free-solid-svg-icons';
import Select from '../../components/Select';
import LocalView from './LocalView';
import PredioView from './PredioView';
import EquipamentoView from './EquipamentoView';

const STATUS_DA_TELA = {
   Carregando: 1,
   Carregado: 2,
   Erro: 3,
};

const TIPO_DE_VISUALIZACAO = {
   Card: 1,
   Grafico: 2,
   Relatorio: 3,
};

let LANG = {};

export default class HomeEmpresa extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         statusDaTela: STATUS_DA_TELA.Carregando,
         tipoDeVisualizacao: TIPO_DE_VISUALIZACAO.Card,
         filtros: {},
         selectedViewNumber: 1,
         quantidadeDeEventosPendentes: 0,
         titulo: props.lang.telaDeMonitoramentoDeTemperaturaEUmidade.titulo,
         predio: null,
         local: null,
      };
      LANG = props.lang;
   }

   componentDidMount = () => {
      this.atualizarQuantidadeDeEventosPendentesId = setInterval(() => {
         this.atualizarQuantidadeDeEventosPendentes();
      }, 60000);
      this.atualizarQuantidadeDeEventosPendentes();
   };

   componentWillUnmount = () => {
      clearInterval(this.atualizarQuantidadeDeEventosPendentesId);
   };

   atualizarQuantidadeDeEventosPendentes = () => {
      this.props.api
         .get('/evento/quantidadedeeventospendentes', false, null, false, false)
         .then((result) => {
            this.setState({ quantidadeDeEventosPendentes: result });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   acoes = () => {
      return (
         <div
            style={{
               display: 'flex',
               flexDirection: 'row',
               backgroundColor: LayoutParams.colors.corSecundaria,
               height: 53,
               paddingTop: 3,
               paddingBottom: 3,
               overflow: 'hidden',
            }}
         >
            <div style={{ paddingLeft: 3, paddingTop: 3, display: 'table-cell' }}>
               <Select
                  name={'local'}
                  defaultValue={this.props.local}
                  getKeyValue={(i) => i.id}
                  getDescription={(i) => i.nome}
                  onSelect={(i) => {
                     this.setState({ local: i }, () => {
                        this.setState({ equipamentos: [], statusDaTela: STATUS_DA_TELA.Carregando }, () => {
                           this.consultarEquipamentos();
                        });
                     });
                  }}
                  formularioPadrao={(select) => {
                     return <LocalView api={this.props.api} select={select} lang={this.props.lang} />;
                  }}
                  noDropDown={true}
                  readOnlyColor={'#ffffff'}
                  getFilterUrl={(text) =>
                     '/local' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                  }
                  placeholder={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.local}
               />
            </div>
            <div style={{ paddingLeft: 3, paddingTop: 3, display: 'table-cell' }}>
               <Select
                  name={'predio'}
                  defaultValue={this.props.predio}
                  getKeyValue={(i) => i.id}
                  getDescription={(i) => i.nome}
                  onSelect={(i) => {
                     this.setState({ predio: i }, () => {
                        this.setState({ equipamentos: [], statusDaTela: STATUS_DA_TELA.Carregando }, () => {
                           this.consultarEquipamentos();
                        });
                     });
                  }}
                  formularioPadrao={(select) => {
                     return <PredioView api={this.props.api} select={select} lang={this.props.lang} />;
                  }}
                  noDropDown={true}
                  readOnlyColor={'#ffffff'}
                  getFilterUrl={(text) =>
                     '/predio' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                  }
                  placeholder={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.predio}
               />
            </div>
            <div style={{ paddingLeft: 3, paddingTop: 3, display: 'table-cell' }}>
               <Select
                  name={'equipamento'}
                  defaultValue={this.state.equipamento}
                  getKeyValue={(i) => i.id}
                  getDescription={(i) => i.nome}
                  onSelect={(i) => {
                     this.setState({ equipamento: i }, () => {
                        this.setState({ equipamentos: [], statusDaTela: STATUS_DA_TELA.Carregando }, () => {
                           this.consultarEquipamentos();
                        });
                     });
                  }}
                  formularioPadrao={(select) => {
                     return (
                        <EquipamentoView
                           api={this.props.api}
                           select={select}
                           lang={this.props.lang}
                           filtroExtra={() => {
                              return { modeloDaTag: 2 };
                           }}
                        />
                     );
                  }}
                  noDropDown={true}
                  readOnlyColor={'#ffffff'}
                  getFilterUrl={(text) =>
                     '/equipamento' +
                     buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text), modeloDaTag: 2 })
                  }
                  placeholder={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.equipamento}
               />
            </div>

            <div
               style={{
                  paddingLeft: 3,
                  margin: 'auto 0px auto auto',
                  height: '100%',
                  minWidth: 140,
                  display: 'table-cell',
               }}
            >
               <ActionButton
                  icon={faPager}
                  height={44}
                  width={44}
                  selected={this.state.tipoDeVisualizacao === TIPO_DE_VISUALIZACAO.Card}
                  title={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.visualizacaoEmCards}
                  light={true}
                  disableFullPaintOnHonver={true}
                  onClick={() => {
                     this.setState({ tipoDeVisualizacao: TIPO_DE_VISUALIZACAO.Card });
                  }}
               />
               <ActionButton
                  icon={faTachometerAlt}
                  height={44}
                  width={44}
                  selected={this.state.tipoDeVisualizacao === TIPO_DE_VISUALIZACAO.Grafico}
                  title={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.visualizacaoEmGrafico}
                  light={true}
                  disableFullPaintOnHonver={true}
                  onClick={() => {
                     this.setState({ tipoDeVisualizacao: TIPO_DE_VISUALIZACAO.Grafico });
                  }}
               />
               <ActionButton
                  icon={faChartBar}
                  height={44}
                  width={44}
                  selected={this.state.tipoDeVisualizacao === TIPO_DE_VISUALIZACAO.Relatorio}
                  title={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.relatorio}
                  light={true}
                  disableFullPaintOnHonver={true}
                  onClick={() => {
                     this.setState({ tipoDeVisualizacao: TIPO_DE_VISUALIZACAO.Relatorio });
                  }}
               />
            </div>
         </div>
      );
   };

   consultarEquipamentos = () => {
      let filtro = {
         somenteComUmidadeOuUmidade: true,
      };

      if (this.state.predio) {
         filtro.predio = this.state.predio.id;
      }

      if (this.state.local) {
         filtro.local = this.state.local.id;
      }

      if (this.state.equipamento) {
         filtro.id = this.state.equipamento.id;
      }

      let query = buildQueryString(null, null, 'id', filtro);

      let endpoint =
         this.state.selectedViewNumber === 1 ? 'dadosdemonitoramentodetemperatura' : 'dadosdemonitoramentodeumidade';

      this.props.api
         .get('/equipamento/' + endpoint + query, false, '', false, false)
         .then((result) => {
            this.setState({ equipamentos: result.items, statusDaTela: STATUS_DA_TELA.Carregado });
         })
         .catch((e) => {
            this.setState({ equipamentos: [], statusDaTela: STATUS_DA_TELA.Erro, mensagemDeErro: e.toString() });
         });
   };

   render() {
      return (
         <div
            id='div-home-empresa-v2'
            style={{ overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}
         >
            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  borderLeft: '5px solid ' + LayoutParams.colors.corSecundaria,
               }}
            >
               <div
                  style={{
                     paddingTop: 0,
                     paddingLeft: 10,
                     height: 45,
                     display: 'flex',
                     flexDirection: 'row',
                  }}
               >
                  <div
                     style={{
                        display: 'table-cell',
                        width: '100%',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{this.state.titulo}</span>
                  </div>
                  <ActionButton
                     icon={faTemperatureLow}
                     title={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.temperatura}
                     selected={this.state.selectedViewNumber === 1}
                     fullSelect={true}
                     onClick={() => {
                        this.setState(
                           {
                              selectedViewNumber: 1,
                              titulo: LANG.telaDeMonitoramentoDeTemperaturaEUmidade.temperaturaEmTempoReal,
                              equipamentos: [],
                           },
                           () => this.consultarEquipamentos()
                        );
                     }}
                  />
                  <ActionButton
                     icon={faTint}
                     title={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.umidade}
                     selected={this.state.selectedViewNumber === 2}
                     fullSelect={true}
                     onClick={() => {
                        this.setState(
                           {
                              selectedViewNumber: 2,
                              titulo: LANG.telaDeMonitoramentoDeTemperaturaEUmidade.umidadeEmTempoReal,
                              equipamentos: [],
                           },
                           () => this.consultarEquipamentos()
                        );
                     }}
                  />
                  <ActionButton
                     icon={faExclamationTriangle}
                     notificationCount={this.state.quantidadeDeEventosPendentes}
                     title={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.eventos}
                     selected={this.state.selectedViewNumber === 3}
                     fullSelect={true}
                     onClick={() => {
                        this.setState({
                           selectedViewNumber: 3,
                           titulo: LANG.telaDeMonitoramentoDeTemperaturaEUmidade.eventos,
                        });
                        this.atualizarQuantidadeDeEventosPendentes();
                     }}
                  />
                  <br />
               </div>
            </div>

            {this.state.selectedViewNumber !== 3 && this.acoes()}

            <ViewController
               selected={this.state.selectedViewNumber}
               v1={
                  <MonitoramentoDeTemperaturaEquipamentoView
                     lang={this.props.lang}
                     api={this.props.api}
                     statusDaTela={this.state.statusDaTela}
                     tipoDeVisualizacao={this.state.tipoDeVisualizacao}
                     consultarEquipamentos={this.consultarEquipamentos}
                     mensagemDeErro={this.state.mensagemDeErro}
                     predio={this.state.predio}
                     local={this.state.local}
                     equipamentos={this.state.equipamentos}
                     equipamento={this.state.equipamento}
                     setEquipamento={(equipamento, callback) => this.setState({ equipamento: equipamento }, callback)}
                  />
               }
               v2={
                  <MonitoramentoDeUmidadeEquipamentoView
                     lang={this.props.lang}
                     api={this.props.api}
                     statusDaTela={this.state.statusDaTela}
                     tipoDeVisualizacao={this.state.tipoDeVisualizacao}
                     consultarEquipamentos={this.consultarEquipamentos}
                     mensagemDeErro={this.state.mensagemDeErro}
                     predio={this.state.predio}
                     local={this.state.local}
                     equipamentos={this.state.equipamentos}
                     equipamento={this.state.equipamento}
                     setEquipamento={(equipamento, callback) => this.setState({ equipamento: equipamento }, callback)}
                  />
               }
               v3={
                  <EventoView
                     lang={this.props.lang}
                     api={this.props.api}
                     esconderTitulo={true}
                     tituloParaAlteracao={LANG.telaDeMonitoramentoDeTemperaturaEUmidade.responder}
                     aposSalvar={() => {
                        this.atualizarQuantidadeDeEventosPendentes();
                     }}
                  />
               }
            ></ViewController>
         </div>
      );
   }
}
