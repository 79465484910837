import { Row, Col, Container, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import Label from '../../components/Label';
import {
   dateTimeToString,
   dateToString,
   emptyStrIfNull,
   formatDate,
   objectToQueryString,
   updateState,
} from '../../utils/Functions';
import Select from '../../components/Select';
import DateInput from '../../components/DateInput';
import Button from '../../components/Button';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Line as ChartLine } from 'react-chartjs-2';
import { showError } from '../../components/Messages';
import TimeInput from '../../components/TimeInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const STATUS_DA_TELA = {
   Carregando1: 1,
   Carregando2: 2,
   Carregando3: 3,
   Carregando4: 4,
   Carregado: 9,
   Erro: -1,
};

let LANG = {};

export default class RelatorioDeUmidadeView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         dataInicial: new Date(),
         horaInicial: '00:00',
         horaFinal: '23:59',
         dataFinal: new Date(),
         tipoDeGrafico: { id: 5, descricao: props.lang.relatorioDeUmidade.tipoDeGrafico.intervaloDe15min },
         tipoDeHistorico: { id: 21, descricao: props.lang.relatorioDeUmidade.tipoDeHistorico.intervaloDe15min },
         graficoVertical: false,
         statusDaTela: STATUS_DA_TELA.Carregado,
         duracao: 30,
      };

      LANG = props.lang;
   }

   componentWillUnmount = () => {
      clearInterval(this.atualizarEtapadoCarregamentoId);
   };

   iniciarProcessamento = () => {
      this.atualizarEtapadoCarregamentoId = setInterval(() => {
         if (this.state.statusDaTela === STATUS_DA_TELA.Carregando1) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando2 });
         } else if (this.state.statusDaTela === STATUS_DA_TELA.Carregando2) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando3 });
         } else if (this.state.statusDaTela === STATUS_DA_TELA.Carregando3) {
            this.setState({ statusDaTela: STATUS_DA_TELA.Carregando4 });
         }
      }, 10000);

      this.setState({ mensagemDeErro: null, statusDaTela: STATUS_DA_TELA.Carregando1 });
   };

   finalizarProcessamentoComSucesso = () => {
      this.setState({ mensagemDeErro: null, statusDaTela: STATUS_DA_TELA.Carregado });
   };

   finalizarProcessamentoComErro = (e) => {
      clearInterval(this.atualizarEtapadoCarregamentoId);
      this.setState({ mensagemDeErro: this.props.api.getErrorMessage(e), statusDaTela: STATUS_DA_TELA.Erro });
   };

   consultar = () => {
      this.setState({
         dados: null,
         dadosDoGrafico: null,
         descricaoDoRelatorio: this.state.tipoDeHistorico.descricao,
      });

      return new Promise((resolve, reject) => {
         if (!this.props.getEquipamento()) {
            showError(LANG.relatorioDeUmidade.informeOEquipamento);
            reject();
            return;
         }

         if (!this.state.dataInicial) {
            showError(LANG.relatorioDeUmidade.informeADataInicial);
            reject();
            return;
         }

         if (!this.state.dataFinal) {
            showError(LANG.relatorioDeUmidade.informeADataFinal);
            reject();
            return;
         }

         this.iniciarProcessamento();

         this.props.api
            .get(
               '/equipamento/relatoriodeumidade?' +
                  objectToQueryString({
                     dataInicial: formatDate(this.state.dataInicial),
                     horaInicial: this.state.horaInicial,
                     horaFinal: this.state.horaFinal,
                     dataFinal: formatDate(this.state.dataFinal),
                     equipamento: this.props.getEquipamento().id,
                     tipoDeGrafico: this.state.tipoDeGrafico ? this.state.tipoDeGrafico.id : null,
                     tipoDeHistorico: this.state.tipoDeHistorico ? this.state.tipoDeHistorico.id : null,
                     duracao: this.state.duracao,
                  }),
               false,
               null,
               false,
               false
            )
            .then((result) => {
               let dadosDoGrafico = null;

               if (result.graficos) {
                  dadosDoGrafico = {
                     labels: [],
                     datasets: [],
                  };

                  let datasetLimiteSuperior = [];
                  let datasetLimiteInferior = [];
                  let datasetDados1 = [];
                  let datasetDados2 = [];

                  if (result.graficos.length > 0) {
                     let grafico = result.graficos[0];
                     for (let i = 0; i < grafico.length; i++) {
                        let item = grafico[i];
                        dadosDoGrafico.labels.push(item.descricao);

                        datasetLimiteSuperior.push(result.equipamento.limiteSuperiorDeUmidade);
                        datasetLimiteInferior.push(result.equipamento.limiteInferiorDeUmidade);
                        datasetDados1.push(item.valor);
                     }

                     if (result.graficos.length > 1) {
                        let grafico = result.graficos[1];
                        for (let i = 0; i < grafico.length; i++) {
                           let item = grafico[i];
                           datasetDados2.push(item.valor);
                        }
                     }

                     dadosDoGrafico.datasets = [
                        {
                           label: LANG.relatorioDeUmidade.limiteSuperior,
                           data: datasetLimiteSuperior,
                           borderColor: '#db4437',
                           yAxisID: 'y',
                           borderWidth: 1.5,
                        },
                        {
                           label: LANG.relatorioDeUmidade.limiteInferior,
                           data: datasetLimiteInferior,
                           borderColor: '#f4b400',
                           yAxisID: 'y',
                           borderWidth: 1.5,
                        },
                        {
                           label:
                              datasetDados2.length > 1
                                 ? LANG.relatorioDeUmidade.umidadeMinima + ' (%)'
                                 : LANG.relatorioDeUmidade.umidade + ' (%)',
                           data: datasetDados1,
                           borderColor: datasetDados2.length > 1 ? '#4285f4' : 'blue',
                           yAxisID: 'y',
                           borderWidth: 1.5,
                        },
                     ];

                     if (datasetDados2.length > 0) {
                        dadosDoGrafico.datasets.push({
                           label: LANG.relatorioDeUmidade.umidadeMaxima + ' (%)',
                           data: datasetDados2,
                           borderColor: 'blue',
                           yAxisID: 'y',
                           borderWidth: 1.5,
                        });
                     }
                  }
               }
               this.setState({ dados: result, dadosDoGrafico: dadosDoGrafico }, () => {
                  this.finalizarProcessamentoComSucesso();
                  resolve();
               });
            })
            .catch((e) => {
               console.log(e);
               this.finalizarProcessamentoComErro(e);
               reject(e);
            });
      });
   };

   render = () => {
      let result = (
         <Fragment>
            <Container style={{ overflowY: 'auto', maxHeight: '100%', minWidth: '100%', margin: 0, paddingTop: 5 }}>
               {!this.props.getEquipamento() && (
                  <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                     <div style={{ margin: 'auto', paddingTop: 30 }}>
                        <span>{LANG.relatorioDeUmidade.selecioneUmEquipamento}</span>
                     </div>
                  </div>
               )}

               {this.props.getEquipamento() && (
                  <Fragment>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{LANG.relatorioDeUmidade.dataInicial}</Label>
                              <DateInput
                                 defaultValue={this.state.dataInicial}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.dataInicial = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{LANG.relatorioDeUmidade.horaInicial}</Label>
                              <TimeInput
                                 defaultValue={this.state.horaInicial}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.horaInicial = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{LANG.relatorioDeUmidade.dataFinal}</Label>
                              <DateInput
                                 defaultValue={this.state.dataFinal}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.dataFinal = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{LANG.relatorioDeUmidade.horaFinal}</Label>
                              <TimeInput
                                 defaultValue={this.state.horaFinal}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.horaFinal = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{LANG.relatorioDeUmidade.resolucaoDoGrafico}</Label>
                              <Select
                                 defaultValue={this.state.tipoDeGrafico}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 allowEmpty={false}
                                 options={[
                                    { id: 0, descricao: LANG.relatorioDeUmidade.tipoDeGrafico.naoMostrar },
                                    { id: 1, descricao: LANG.relatorioDeUmidade.tipoDeGrafico.todasAsAfericoes },
                                    { id: 2, descricao: LANG.relatorioDeUmidade.tipoDeGrafico.diario },
                                    { id: 3, descricao: LANG.relatorioDeUmidade.tipoDeGrafico.hora },
                                    { id: 4, descricao: LANG.relatorioDeUmidade.tipoDeGrafico.intervaloDe30min },
                                    { id: 5, descricao: LANG.relatorioDeUmidade.tipoDeGrafico.intervaloDe15min },
                                 ]}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.tipoDeGrafico = i;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{LANG.relatorioDeUmidade.listagemDeAfericoes}</Label>
                              <Select
                                 defaultValue={this.state.tipoDeHistorico}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 allowEmpty={false}
                                 options={[
                                    { id: 0, descricao: LANG.relatorioDeUmidade.tipoDeHistorico.naoMostrar },
                                    { id: 1, descricao: LANG.relatorioDeUmidade.tipoDeHistorico.todasAsAfericoes },
                                    { id: 2, descricao: LANG.relatorioDeUmidade.tipoDeHistorico.diario },
                                    { id: 3, descricao: LANG.relatorioDeUmidade.tipoDeHistorico.hora },
                                    { id: 4, descricao: LANG.relatorioDeUmidade.tipoDeHistorico.intervaloDe30min },
                                    { id: 5, descricao: LANG.relatorioDeUmidade.tipoDeHistorico.intervaloDe15min },
                                    {
                                       id: 20,
                                       descricao: LANG.relatorioDeUmidade.tipoDeHistorico.somentAfericoesForaDsLimites,
                                    },
                                    {
                                       id: 21,
                                       descricao: LANG.relatorioDeUmidade.tipoDeHistorico.usarAMesmaResolucaoDoGrafico,
                                    },
                                    { id: 6, descricao: LANG.relatorioDeUmidade.tipoDeHistorico.eventos },
                                    {
                                       id: 7,
                                       descricao: LANG.relatorioDeUmidade.tipoDeHistorico.eventosComJustificativa,
                                    },
                                 ]}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.tipoDeHistorico = i;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>

                        {this.state.tipoDeHistorico &&
                           (this.state.tipoDeHistorico.id === 6 || this.state.tipoDeHistorico.id === 7) && (
                              <Col>
                                 <FormGroup>
                                    <Label>{LANG.relatorioDeUmidade.duracao}</Label>
                                    <Select
                                       defaultValue={this.state.duracao}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={true}
                                       options={[
                                          { id: 15, descricao: LANG.relatorioDeUmidade.acimaDe15Minutos },
                                          { id: 30, descricao: LANG.relatorioDeUmidade.acimaDe30Minutos },
                                          { id: 60, descricao: LANG.relatorioDeUmidade.acimaDe60Minutos },
                                          { id: 99, descricao: LANG.relatorioDeUmidade.todos },
                                       ]}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             state.duracao = i ? (i.id === 99 ? null : i.id) : null;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           )}
                     </Row>
                     <Row>
                        <Col>
                           <ReactToPrint content={() => this.reportComponentRef}>
                              <PrintContextConsumer>
                                 {({ handlePrint }) => (
                                    <Row>
                                       <Col style={{ maxWidth: 180 }}>
                                          <Button
                                             onClickAsync={() => {
                                                return new Promise((resolve, reject) => {
                                                   this.consultar()
                                                      .then(() => {
                                                         resolve();
                                                      })
                                                      .catch(reject);
                                                });
                                             }}
                                             inProgressText={LANG.relatorioDeUmidade.gerando}
                                             text={LANG.relatorioDeUmidade.gerar}
                                          />
                                       </Col>
                                       {this.state.dados && (
                                          <Col style={{ maxWidth: 180, paddingLeft: 0 }}>
                                             <Button
                                                onClick={() => {
                                                   handlePrint();
                                                }}
                                                text={LANG.relatorioDeUmidade.imprimir}
                                             />
                                          </Col>
                                       )}
                                    </Row>
                                 )}
                              </PrintContextConsumer>
                           </ReactToPrint>
                        </Col>
                     </Row>
                  </Fragment>
               )}

               <br />
               <br />

               {this.state.statusDaTela >= STATUS_DA_TELA.Carregando1 &&
                  this.state.statusDaTela <= STATUS_DA_TELA.Carregando4 && (
                     <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                        <div style={{ margin: 'auto', paddingTop: 30 }}>
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando1 && (
                              <span>{LANG.relatorioDeUmidade.consultandoOHistoricoDeUmidade}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando2 && (
                              <span>{LANG.relatorioDeUmidade.baixandoInformacoes}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando3 && (
                              <span>{LANG.relatorioDeUmidade.processandoInformacoes}</span>
                           )}
                           {this.state.statusDaTela === STATUS_DA_TELA.Carregando4 && (
                              <span>{LANG.relatorioDeUmidade.preparandoExibicao}</span>
                           )}
                        </div>
                     </div>
                  )}

               {this.state.statusDaTela === STATUS_DA_TELA.Erro && (
                  <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                     <div style={{ margin: 'auto', paddingTop: 30 }}>
                        <span>{this.state.mensagemDeErro}</span>
                     </div>
                  </div>
               )}

               {this.state.statusDaTela === STATUS_DA_TELA.Carregado && (
                  <div>
                     <div ref={(el) => (this.reportComponentRef = el)}>
                        {this.props.getEquipamento() && this.state.dados && this.relatorio()}
                     </div>
                     <br />
                     <br />
                  </div>
               )}
            </Container>
         </Fragment>
      );

      return result;
   };

   relatorio = () => {
      return (
         <div style={{ paddingRight: 0 }}>
            <div style={{ minWidth: '100%', backgroundColor: '#999', minHeight: 1 }} />

            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div style={{ display: 'table-cell', fontSize: 16 }}>
                  <span>{LANG.relatorioDeUmidade.relatorioDeUmidade}</span>
               </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div style={{ display: 'table-cell', fontSize: 22 }}>
                  <span>{this.state.dados.equipamento.nome}</span>
               </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div style={{ display: 'table-cell', width: 180 }}>
                  <span>
                     {LANG.relatorioDeUmidade.numeroDeSerie +
                        ': ' +
                        emptyStrIfNull(
                           this.state.dados.equipamento.numeroDeSerie,
                           '<' + LANG.relatorioDeUmidade.naoInformado + '>'
                        )}
                  </span>
               </div>
               <div style={{ display: 'table-cell', paddingLeft: 10 }}>
                  <span>
                     {LANG.relatorioDeUmidade.patrimonio +
                        ': ' +
                        emptyStrIfNull(
                           this.state.dados.equipamento.numeroDePatrimonio,
                           '<' + LANG.relatorioDeUmidade.naoInformado + '>'
                        )}
                  </span>
               </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div style={{ display: 'table-cell', width: 180 }}>
                  <span>
                     {'TAG: ' +
                        (this.state.dados.equipamento.tag && this.state.dados.equipamento.tag.mac
                           ? this.state.dados.equipamento.tag.mac
                           : '')}
                  </span>
               </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div style={{ display: 'table-cell' }}>
                  <span>
                     {LANG.relatorioDeUmidade.periodo +
                        ': ' +
                        dateToString(this.state.dataInicial) +
                        ' - ' +
                        dateToString(this.state.dataFinal)}
                  </span>
               </div>
            </div>

            <div style={{ paddingTop: 0 }}>
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell', width: 180 }}>
                     <span>
                        {LANG.relatorioDeUmidade.umidadeAtual +
                           ': ' +
                           (this.state.dados.equipamento.umidadeAtual
                              ? this.state.dados.equipamento.umidadeAtual + '%'
                              : '')}
                     </span>
                  </div>

                  <div style={{ display: 'table-cell' }}>
                     <span>
                        {LANG.relatorioDeUmidade.minimaEMaxima +
                           ': ' +
                           (this.state.dados.equipamento.umidadeMinima
                              ? this.state.dados.equipamento.umidadeturaMinima + '%'
                              : '') +
                           '/' +
                           (this.state.dados.equipamento.umidadeMaxima
                              ? this.state.dados.equipamento.umidadeMaxima + '%'
                              : '')}
                     </span>
                  </div>
               </div>
            </div>

            {this.state.dadosDoGrafico && this.state.dados.equipamento && (
               <div
                  className={this.state.graficoVertical ? 'landscape-print' : ''}
                  style={{
                     breakBefore: this.state.graficoVertical ? 'always' : null,
                  }}
               >
                  <div
                     style={{
                        paddingTop: 8,
                        paddingRight: 20,
                     }}
                  >
                     <ChartLine
                        ref={(c) => (this.chartRef = c)}
                        options={{
                           responsive: true,
                           animation: false,
                           plugins: {
                              legend: {
                                 position: 'bottom',
                                 fontSize: 8,
                              },
                           },
                           elements: {
                              point: {
                                 radius: 0,
                              },
                           },
                           scales: {
                              x: {
                                 title: {
                                    display: true,
                                    text: 'Tempo',
                                 },
                              },
                              y: {
                                 type: 'linear',
                                 display: true,
                                 position: 'left',
                                 title: {
                                    display: true,
                                    text: LANG.relatorioDeUmidade.umidade + ' (%)',
                                 },
                              },
                           },
                        }}
                        data={this.state.dadosDoGrafico}
                     />
                  </div>
               </div>
            )}

            <div style={{ minWidth: '100%', backgroundColor: '#999', minHeight: 1 }} />

            {this.state.dados &&
               this.state.dados.historico &&
               this.state.tipoDeHistorico &&
               this.state.dados.historico.length > 0 && (
                  <React.Fragment>
                     <div style={{ paddingTop: 8, pageBreakBefore: 'always', fontSize: 16 }}>
                        <strong>{LANG.relatorioDeUmidade.historico + ' - ' + this.state.descricaoDoRelatorio}</strong>
                     </div>

                     <LinhaDeCabecalho>
                        <Coluna texto={LANG.relatorioDeUmidade.umidade + ' (%)'} largura={'25%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.data} largura={'50%'} />
                        {/* <Coluna texto={'Permanência'} largura={'15%'} /> */}
                        <Coluna texto={LANG.relatorioDeUmidade.situacao} largura={'25%'} />
                     </LinhaDeCabecalho>

                     {this.state.dados.historico.map((item, index) => {
                        return (
                           <Fragment key={index}>
                              <Linha>
                                 <Coluna texto={item.descricao} largura={'25%'} />
                                 <Coluna texto={item.periodo} largura={'50%'} />
                                 {/* <Coluna texto={item.duracao ? item.duracao : '-'} largura={'15%'} /> */}
                                 <Coluna texto={this.getSituacao(item.situacao)} largura={'25%'} />
                              </Linha>
                              <Linha>
                                 <div
                                    style={{
                                       width: '100%',
                                       backgroundColor: 'lightgray',
                                       minHeight: 1,
                                    }}
                                 />
                              </Linha>
                           </Fragment>
                        );
                     })}
                  </React.Fragment>
               )}

            {this.state.dados &&
               this.state.dados.historico &&
               this.state.tipoDeHistorico &&
               this.state.dados.eventos &&
               this.state.dados.eventos.length > 0 && (
                  <React.Fragment>
                     <div style={{ paddingTop: 8, pageBreakBefore: 'always', fontSize: 16 }}>
                        <strong>{this.state.descricaoDoRelatorio}</strong>
                     </div>

                     <LinhaDeCabecalho>
                        <Coluna texto={LANG.relatorioDeUmidade.descricao} largura={'40%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.data} largura={'15%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.dataDeRegularizacao} largura={'15%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.duracao} largura={'15%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.situacao} largura={'15%'} />
                     </LinhaDeCabecalho>

                     {this.state.dados.eventos.map((item, index) => {
                        return (
                           <React.Fragment key={index}>
                              <Linha>
                                 <Coluna texto={item.descricao} largura={'40%'} />
                                 <Coluna texto={dateTimeToString(item.dataDaIrregularidade)} largura={'15%'} />
                                 <Coluna texto={dateTimeToString(item.dataDeRegularizacao)} largura={'15%'} />
                                 <Coluna texto={item.duracao} largura={'15%'} />
                                 <Coluna texto={item.situacao.descricao} largura={'15%'} />
                              </Linha>
                              <Linha>
                                 <div
                                    style={{
                                       width: '100%',
                                       backgroundColor: 'lightgray',
                                       minHeight: 1,
                                    }}
                                 />
                              </Linha>
                           </React.Fragment>
                        );
                     })}
                  </React.Fragment>
               )}

            {this.state.dados &&
               this.state.dados.historico &&
               this.state.tipoDeHistorico &&
               this.state.dados.eventosComResposta &&
               this.state.dados.eventosComResposta.length > 0 && (
                  <React.Fragment>
                     <div style={{ paddingTop: 8, pageBreakBefore: 'always', fontSize: 16 }}>
                        <strong>{this.state.descricaoDoRelatorio}</strong>
                     </div>

                     <LinhaDeCabecalho>
                        <Coluna texto={LANG.relatorioDeUmidade.data} largura={'15%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.descricao} largura={'35%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.dataDeRegularizacao} largura={'15%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.duracao} largura={'20%'} />
                        <Coluna texto={LANG.relatorioDeUmidade.situacao} largura={'15%'} />
                     </LinhaDeCabecalho>

                     {this.state.dados.eventosComResposta.map((item, index) => {
                        return (
                           <div key={index} style={{ border: '1px solid' }}>
                              <Linha>
                                 <Coluna texto={dateTimeToString(item.dataDaIrregularidade)} largura={'15%'} />
                                 <Coluna texto={item.descricao} largura={'35%'} />
                                 <Coluna texto={dateTimeToString(item.dataDeRegularizacao)} largura={'15%'} />
                                 <Coluna texto={item.duracao} largura={'20%'} />
                                 <Coluna texto={item.situacao.descricao} largura={'15%'} />
                              </Linha>

                              <Linha>
                                 <div style={{ display: 'table-cell', width: '50%', paddingLeft: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                       <div style={{ display: 'table-cell' }}>
                                          <span>{LANG.relatorioDeUmidade.causaProvavel + ':'}</span>
                                       </div>
                                       <div style={{ display: 'table-cell', paddingLeft: 4 }}>
                                          <span style={{ whiteSpace: 'break-spaces' }}>{item.causaProvavel}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div style={{ display: 'table-cell', width: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                       <div style={{ display: 'table-cell' }}>
                                          <span>{LANG.relatorioDeUmidade.acaoRealizadaParaRegularizacao + ':'}</span>
                                       </div>
                                       <div style={{ display: 'table-cell', paddingLeft: 4 }}>
                                          <span style={{ whiteSpace: 'break-spaces' }}>
                                             {item.acaoRealizadaParaRegularizacao}
                                          </span>
                                       </div>
                                    </div>
                                 </div>
                              </Linha>

                              <Linha>
                                 <div style={{ display: 'table-cell', width: '50%', paddingLeft: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                       <div style={{ display: 'table-cell' }}>
                                          <span>
                                             {LANG.relatorioDeUmidade.acaoRealizadaParaPrevenirReincidencia + ':'}
                                          </span>
                                       </div>
                                       <div style={{ display: 'table-cell', paddingLeft: 4 }}>
                                          <span style={{ whiteSpace: 'break-spaces' }}>
                                             {item.acaoRealizadaParaPrevenirReincidencia}
                                          </span>
                                       </div>
                                    </div>
                                 </div>
                                 <div style={{ display: 'table-cell', width: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                       <div style={{ display: 'table-cell' }}>
                                          <span>{LANG.relatorioDeUmidade.outrasObservacoes + ':'}</span>
                                       </div>
                                       <div style={{ display: 'table-cell', paddingLeft: 4 }}>
                                          <span style={{ whiteSpace: 'break-spaces' }}>{item.observacao}</span>
                                       </div>
                                    </div>
                                 </div>
                              </Linha>
                              <Linha>
                                 <div
                                    style={{
                                       width: '100%',
                                       backgroundColor: 'lightgray',
                                       minHeight: 1,
                                    }}
                                 />
                              </Linha>
                           </div>
                        );
                     })}
                  </React.Fragment>
               )}
         </div>
      );
   };

   getSituacao = (situacao) => {
      let result = '';

      switch (situacao) {
         case -1:
            result = (
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell' }}>
                     <span>{LANG.relatorioDeUmidade.alerta}</span>
                  </div>
                  <div style={{ display: 'table-cell', color: 'blue', paddingLeft: 3 }}>
                     <FontAwesomeIcon icon={faArrowDown} />
                  </div>
               </div>
            );
            break;
         case 0:
            result = LANG.relatorioDeUmidade.normal;
            break;
         case 1:
            result = (
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell' }}>
                     <span>{LANG.relatorioDeUmidade.alerta}</span>
                  </div>
                  <div style={{ display: 'table-cell', color: 'red', paddingLeft: 3 }}>
                     <FontAwesomeIcon icon={faArrowUp} />
                  </div>
               </div>
            );
            break;
         case 2:
            result = (
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'table-cell' }}>
                     <span>{LANG.relatorioDeUmidade.alerta}</span>
                  </div>
                  <div style={{ display: 'table-cell', color: 'blue', paddingLeft: 3 }}>
                     <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                  <div style={{ display: 'table-cell', color: 'red', paddingLeft: 3 }}>
                     <FontAwesomeIcon icon={faArrowUp} />
                  </div>
               </div>
            );
            break;

         default:
            break;
      }
      return result;
   };
}

class Linha extends React.Component {
   render() {
      return (
         <div className={'linha-relatorio'} style={{ display: 'flex', flexDirection: 'row' }}>
            {this.props.children}
         </div>
      );
   }
}

class LinhaDeCabecalho extends React.Component {
   render() {
      return (
         <div
            className={'linha-relatorio'}
            style={{
               display: 'flex',
               flexDirection: 'row',
               fontWeight: 'bold',
               border: '1px solid',
               backgroundColor: 'lightgray',
            }}
         >
            {this.props.children}
         </div>
      );
   }
}

const Coluna = ({ texto, largura }) => {
   return (
      <div style={{ display: 'table-cell', width: largura }}>
         <span>{texto}</span>
      </div>
   );
};
