import { faBuilding, faHdd, faIdCard } from '@fortawesome/free-regular-svg-icons';
import {
   faBars,
   faCogs,
   faHome,
   faInfoCircle,
   faListAlt,
   faStore,
   faTag,
   faTools,
   faUser,
   faMapMarkerAlt,
   faChartBar,
   faBroadcastTower,
   faSms,
} from '@fortawesome/free-solid-svg-icons';

export class Menus {
   constructor() {
      this.temAcessoARotina = this.temAcessoARotina.bind(this);
   }

   temAcessoARotina = (sessionManager, rotina) => {
      let result = sessionManager && rotina ? sessionManager.temAcessoARotina(rotina) : false;
      return result;
   };

   getMenusParaAceiteDeTermosDeUso = (sessionManager, lang) => {
      let result = [
         { key: 10, descricao: lang.menu.inicio, icone: faHome, fontWeight: 500, rota: '/', estaHabilitado: true },
         {
            key: 30,
            descricao: lang.menu.sobre,
            icone: faInfoCircle,
            fontWeight: 500,
            estaHabilitado: true,
            rota: '/about',
         },
      ];
      return result;
   };

   getMenusTecnometrics = (sessionManager, lang) => {
      let result = [
         { key: 10, descricao: lang.menu.inicio, icone: faHome, fontWeight: 500, rota: '/', estaHabilitado: true },
         {
            key: 20,
            descricao: lang.menu.cadastro,
            icone: faListAlt,
            fontWeight: 500,
            rota: null,
            estaHabilitado: true,
            subMenu: [
               {
                  key: 1011,
                  descricao: lang.menu.perfilDeUsuario,
                  icone: faListAlt,
                  rota: '/1011',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1011),
               },
               {
                  key: 1021,
                  descricao: lang.menu.funcionarios,
                  icone: faIdCard,
                  rota: '/1021',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1021),
               },
               {
                  key: 1101,
                  descricao: lang.menu.empresas,
                  icone: faStore,
                  rota: '/1101',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1101),
               },
            ],
         },
         {
            key: 25,
            descricao: lang.menu.configuracoes,
            icone: faCogs,
            fontWeight: 500,
            estaHabilitado: true,
            subMenu: [
               {
                  key: 1041,
                  descricao: lang.menu.parametrosDoSistema,
                  icone: faCogs,
                  rota: '/1041',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1041),
               },
               {
                  key: 1031,
                  descricao: lang.menu.rotinasDoSistema,
                  icone: faListAlt,
                  rota: '/1031',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1031),
               },
               {
                  key: 1061,
                  descricao: lang.menu.cargos,
                  icone: faListAlt,
                  rota: '/1061',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1061),
               },
               {
                  key: 1381,
                  descricao: lang.menu.Termo,
                  icone: faListAlt,
                  rota: '/1381',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1381),
               },
            ],
         },
         {
            key: 26,
            descricao: lang.menu.manutencao,
            icone: faTools,
            fontWeight: 500,
            estaHabilitado: true,
            subMenu: [
               {
                  key: 9001,
                  descricao: lang.menu.logs,
                  icone: faBars,
                  rota: '/9001',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 9001),
               },
               {
                  key: 90006,
                  descricao: "Pacotes",
                  icone: faBars,
                  rota: '/pacotes',
                  estaHabilitado: true,
               },
            ],
         },
         {
            key: 30,
            descricao: lang.menu.sobre,
            icone: faInfoCircle,
            fontWeight: 500,
            estaHabilitado: true,
            rota: '/about',
         },
      ];
      return result;
   };

   getMenusDeEmpresa = (sessionManager, lang) => {
      let result = [
         { key: 10, descricao: lang.menu.inicio, icone: faHome, fontWeight: 500, rota: '/', estaHabilitado: true },

         {
            key: 1011,
            descricao: lang.menu.perfilDeUsuario,
            icone: faListAlt,
            rota: '/1011',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1011),
         },
         {
            key: 1121,
            descricao: lang.menu.usuariosDeEmpresa,
            icone: faUser,
            rota: '/1121',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1121),
         },
         {
            key: 1411,
            descricao: lang.menu.gateway,
            icone: faBroadcastTower,
            rota: '/1411',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1411),
         },
         {
            key: 1401,
            descricao: lang.menu.tag,
            icone: faTag,
            rota: '/1401',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1401),
         },
         {
            key: 1461,
            descricao: lang.menu.predio,
            icone: faBuilding,
            rota: '/1461',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1461),
         },
         {
            key: 1421,
            descricao: lang.menu.local,
            icone: faMapMarkerAlt,
            rota: '/1421',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1421),
         },
         {
            key: 1431,
            descricao: lang.menu.equipamento,
            icone: faHdd,
            rota: '/1431',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1431),
         },
         {
            key: 1481,
            descricao: lang.menu.servicoDeNotificacoes,
            icone: faSms,
            rota: '/1481',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1481),
         },
         {
            key: 35,
            descricao: lang.menu.painelDeControle,
            icone: faChartBar,
            fontWeight: 500,
            estaHabilitado: true,
            rota: '/panel',
         },
         {
            key: 1041,
            descricao: lang.menu.parametrosDoSistema,
            icone: faCogs,
            rota: '/1041',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1041),
         },
      ];
      return result;
   };
}
